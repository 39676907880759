import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Collapse, Table, Form, Pagination } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { useAuth } from '../utils/auth';

const BlockedUsers = () => {
  const navigate = useNavigate();
  const [siteData, setSiteData] = useState([]);
  const [isOpenUsers, setIsOpenUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      fetchBlockedUsers(token);
    }
  }, [navigate]);

  const fetchBlockedUsers = async (token) => {
    const domain = localStorage.getItem('domain');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/blockedsiteUsersAdmin`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          domain: domain,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const responseData = await response.json();
      if (responseData.status === 'success' && Array.isArray(responseData.users)) {
        setSiteData(responseData.users);
      } else {
        console.error('Unexpected response data:', responseData);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };


const handleUnblockUser = async (email) => {
  setLoading(true);
  const token = localStorage.getItem('userToken');
  const domain = localStorage.getItem('domain');

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/boss/unblockUser`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ auth: token, domain: domain, email: email }),
    });

    const result = await response.json();
    if (result.status === 'success') {
      await fetchBlockedUsers(token);
      Swal.fire({
        title: 'Success',
        text: 'User unblocked successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: result.message || 'Failed to unblock user.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  } catch (error) {
    Swal.fire({
      title: 'Error',
      text: 'An error occurred while unblocking the user.',
      icon: 'error',
      confirmButtonText: 'OK',
    });
    console.error('Error unblocking user:', error);
  } finally {
    setLoading(false);
  }
};


  const handleSearch = (e) => setSearchTerm(e.target.value);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const filteredSiteData = siteData.filter((user) =>
    user.fu_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.tani.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSiteData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredSiteData.length / itemsPerPage);

  return (
    <Card className="mb-1">
      <Card.Header style={{ backgroundColor: 'red', textAlign: 'center', fontWeight: 'bold' }}>
        <Button
          variant="link"
          onClick={() => setIsOpenUsers(!isOpenUsers)}
          aria-controls="collapse-card-3"
          aria-expanded={isOpenUsers}
          style={{ color: 'white' }}
        >
          Auto Blocked Users
        </Button>
      </Card.Header>
      <Collapse in={isOpenUsers}>
        <Card.Body>
          <Form.Control
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={handleSearch}
            className="mb-3"
          />
          {currentItems.length === 0 ? (
            <p>No users found.</p>
          ) : (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((user) => (
                    <tr key={user.id}>
                      <td>{user.fu_name}</td>
                      <td>{user.tani}<br />
					  Bal: ₦{parseFloat(user.owomi).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                   
                      <td>
                        <Button
                          onClick={() => handleUnblockUser(user.tani)}
                          disabled={loading}
                        >
                          {loading ? 'Unblocking...' : 'Unblock'}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination>
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                {Array.from({ length: totalPages }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
              </Pagination>
            </>
          )}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default BlockedUsers;
