import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Form, Button, Collapse } from 'react-bootstrap';
import { useAuth } from '../utils/auth';

const UserEmails = () => {
  const navigate = useNavigate();
  const [emails, setEmails] = useState('');
  const [phones, setPhones] = useState('');
  const [botSvs, setBotSvs] = useState(false);
  const [loading, setLoading] = useState(true);

  useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const domain = localStorage.getItem('domain');
      const token = localStorage.getItem('userToken');

      if (!token) {
        navigate('/');
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/usercontacts`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ domain }),
        });

        if (!response.ok) throw new Error('Failed to fetch user contacts');

        const data = await response.json();
        setEmails(data.emails || '');
        setPhones(data.phones || '');
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard');
  };

  return (
    <Card className="mb-3">
      <Card.Header style={{ backgroundColor: 'purple', textAlign: 'center', fontWeight: 'bold' }}>
        <Button
          variant="link"
          onClick={() => setBotSvs(!botSvs)}
          aria-controls="collapse-user-contacts"
          aria-expanded={botSvs}
          style={{ color: 'white' }}
        >
          User Contacts
        </Button>
      </Card.Header>
      <Collapse in={botSvs}>
        <Card.Body id="collapse-user-contacts">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <Card className="mb-3">
                <Card.Header>Email Addresses</Card.Header>
                <Card.Body>
                  <Form.Control as="textarea" rows={5} value={emails} readOnly />
                  <Button className="mt-2" onClick={() => copyToClipboard(emails)}>
                    Copy Emails
                  </Button>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>Phone Numbers</Card.Header>
                <Card.Body>
                  <Form.Control as="textarea" rows={5} value={phones} readOnly />
                  <Button className="mt-2" onClick={() => copyToClipboard(phones)}>
                    Copy Phone Numbers
                  </Button>
                </Card.Body>
              </Card>
            </>
          )}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default UserEmails;
