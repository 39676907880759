import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaBars, FaSignOutAlt, FaWifi, FaHistory, FaGraduationCap, FaEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { useAuth } from '../utils/auth';
import Swal from 'sweetalert2';
import './Dashboard.css';

import MyDataPlans from '../components/MyDataPlans';
import Bots from '../components/Bots';
import RegSetup from '../components/RegSetup';
import ServiceApi from '../components/ServiceApi';
import SiteDataPlan from '../components/SiteDataPlan';
import UserEmails from '../components/Emails';
import SiteUserList from '../components/SiteUserList';
import UniversalData from '../components/UniversalData';
import BlockedUsers from '../components/BlockedUsers';
import SiteOrdersList from '../components/SiteOrdersList';
import AllOrderHistory from '../components/AllOrderHistory';




function Dashboard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuPanelRef = useRef(null); 
  const [clickCount, setClickCount] = useState(0); 
  const [showUniversalData, setShowUniversalData] = useState(false);

  useAuth(); 

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    const domain = localStorage.getItem('domain'); 

    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
    }
  }, [navigate]);

  const handleSignOut = () => {
    localStorage.clear();
    navigate('/');
  };

  const validateToken = async (token) => {
    const domain = localStorage.getItem('domain');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfoAdm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, 
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate token');
      }

      const responseData = await response.json();
      if (responseData.status === 'failed') {
        localStorage.clear();
        navigate('/');
      } else {
        setData(responseData.user);
        setIsLoading(false);
      }
    } catch (error) {
      localStorage.clear();
      navigate('/');
    }
  };

  function handleOutsideClick(e) {
    if (menuPanelRef.current && !menuPanelRef.current.contains(e.target)) {
      setIsMenuOpen(false); 
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const domain = localStorage.getItem('domain');
    if (domain === 'localhost' || domain === 'cart9.com.ng') {
      document.addEventListener('click', () => {
        setClickCount(prevCount => {
          const newCount = prevCount + 1;
          if (newCount >= 10) {
            setShowUniversalData(true);
          }
          return newCount;
        });
      });
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="dashboard">
      <Container className="mb-5">
        <header className="top-nav">
          <Row className="align-items-center">
            <Col md={12} className="d-flex justify-content-between align-items-center">
              <span><b>  Hello {data && data.fullName.split(' ')[0]} 🥰
              </b></span>

              <FaBars  onClick={handleSignOut}style={{ cursor: 'pointer' }} />
            </Col>
          </Row>
        </header>

        <br />
        <hr />

        <SiteOrdersList />
        <AllOrderHistory />
        <SiteUserList />
        <BlockedUsers />
        <MyDataPlans />
        <ServiceApi />
        <SiteDataPlan />
        <UserEmails />

        {showUniversalData && <UniversalData />} 

        <RegSetup />
        <Bots />
      </Container>
    </div>
  );
}

export default Dashboard;
